// extracted by mini-css-extract-plugin
export var adminAnnotation = "index-module--adminAnnotation--xJdqN";
export var backToOverview = "index-module--backToOverview--v0MGf";
export var copyProduct = "index-module--copyProduct--C0Uvw";
export var customContainer = "index-module--customContainer--u7SRl";
export var deleteButton = "index-module--deleteButton--gJIWw";
export var deleteIcon = "index-module--deleteIcon--l6koJ";
export var dropDown = "index-module--dropDown--8Du-A";
export var dropDownChevron = "index-module--dropDownChevron--w4aT7";
export var editButton = "index-module--editButton--UNE9l";
export var heading = "index-module--heading--9v3EP";
export var icon = "index-module--icon--WNLhE";
export var inputField = "index-module--inputField--tTS9i";
export var inputLabel = "index-module--inputLabel--jT1gy";
export var modal = "index-module--modal--WD3fg";
export var modalTitle = "index-module--modalTitle--9lwn6";
export var shieldIcon = "index-module--shieldIcon--xdxvg";
export var submitButton = "index-module--submitButton--3NuWy";
export var textArea = "index-module--textArea--F3NJV";