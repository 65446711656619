import * as React from "react";
// @ts-ignore
import * as style from '../index.module.scss';
// @ts-ignore
import * as mainStyle from '../../../theme/main.module.scss';
import {Col, Container, Form, Row} from "react-bootstrap";
import {FC, useEffect, useState} from "react";
import {Client, handleApiError} from "../../../services/ApiService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect, ConnectedProps} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {setLocale} from "yup";
import {faArrowLeft, faTrash} from "@fortawesome/pro-light-svg-icons";

type Props = {
    authKey: string,
    obj: any,
}

type FormInputs = {
    Price: number,
    Scale: number
}

const schema = yup.object().shape({
    Price: yup.number().required('Bitte gib einen Preis an'),
    Scale: yup.number().required('Bitte gib eine Ab Menge an')
})

const ScaledPrice: FC<Props> = ({authKey, obj}: Props, {id}) => {
    const [price, setPrice] = useState(obj ? obj.Price : 0);
    const [scale, setScale] = useState(obj ? obj.Scale : 0);
    const [priceError, setPriceError] = useState("");
    const [scaleError, setScaleError] = useState("");
    const [deleted, setDeleted] = useState<boolean>(false);
    const [delay, setDelay] = useState<null | number>(null);

    useEffect(() => {
        schema.validate({
            Price: price,
            Scale: scale
        }).then(() => {
            clearTimeout(delay);
            setScaleError("");
            setPriceError("");
            setTimeout(() => {
                Client.Auth.authPut(authKey, 'ScaledPrice', obj.ID, {
                    Price: price,
                    Scale: scale,
                })
            }, 1000);
        }).catch((err) => {
            if (err.errors[0].includes("Scale")) {
                setScaleError("Bitte gib eine Ab Menge an")
            } else {
                setPriceError("Bitte gib einen Preis an");
            }
        })
    }, [price, scale])

    const deleteMe = () => {
        Client.Auth.authDelete(authKey, 'ScaledPrice', obj.ID).then(() => {
            setDeleted(true);
        })
    }

    if (deleted) {
        return null;
    }

    // @ts-ignore
    return (
        <>
            <Col xs={5} lg={3}>
                <Form.Group controlId="formScaleAmount" className="mb-2">
                    <Form.Label className={style.inputLabel}>Ab Menge</Form.Label>
                    <Form.Control type="number" className={style.inputField}
                                  value={scale} min={2}
                                  onChange={(e) => setScale(e.target.value)}/>
                    <Form.Text className={mainStyle.errorMsg}>
                        {scaleError}
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col xs={5} lg={3}>
                <Form.Group controlId="formScalePrice" className="mb-2">
                    <Form.Label className={style.inputLabel}>Preis pro Stück</Form.Label>
                    <Form.Control type="text" className={style.inputField}
                                  defaultValue={price} onChange={(e) => setPrice(e.target.value)}/>
                    <Form.Text className={mainStyle.errorMsg}>
                        {priceError}
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col xs={2} lg={6} className={"d-flex align-items-center"}>
                 <span className={style.deleteIcon} onClick={deleteMe}>
                    <FontAwesomeIcon icon={faTrash}/>
                </span>
            </Col>
        </>
    )
}

export default ScaledPrice;
